import _ from "underscore";
import { Connection } from "common/libs/connection";

const CONNECT_STATUS = {
  REQUEST: "request",
  CONNECTED: "connected",
  UNCONNECTED: "unconnected"
};

const TICKET_STATUS = {
  OPEN: "open",
  UNSUPPORTED: "unsupported",
  ANSWERED: "answered",
  QUIT: "quit"
};

const TICKET_SEND_TAG = {
  BOT: "ticket-bot",
  OPERATOR: "ticket-operator"
};

const FEEDBACK_STATUS = {
  RESOLVED: "resolved",
  UNRESOLVED: "unresolved"
};

export default {
  state: {
    connectStatus: CONNECT_STATUS.UNCONNECTED,
    roomId: "",
    isSending: false
  },
  mutations: {
    setConnectStatus(state, payload) {
      state.connectStatus = payload;
    },
    setIsSending(state, payload) {
      state.isSending = payload;
    },
    initIsSending(state) {
      state.isSending = false;
    },
    setRoomId(state, payload) {
      state.roomId = payload;
    }
  },
  actions: {
    async initConnection({ commit, rootState, state, dispatch }) {
      if (!Connection.onSalesTime(rootState.constObj)) {
        dispatch("postTicket", {
          mode: "operator",
          status: TICKET_STATUS.UNSUPPORTED
        });
        const inqueryMessage = {
          from: "operator",
          content: {
            text: rootState.constObj.CONNECT_OFFICIAL_INQUERY
          },
          status: TICKET_STATUS.UNSUPPORTED
        };
        commit("addMessage", inqueryMessage);
        return;
      }

      const firstOperatorMessage = {
        from: "operator",
        content: {
          text: rootState.constObj.CONNECTING_MESSAGE,
          choices: rootState.constObj.REQUEST_DISCONNECT_CHOICE
        },
        status: rootState.botAction.stepStatus
      };
      commit("addMessage", firstOperatorMessage);
      dispatch("postTicket", { mode: "operator", status: TICKET_STATUS.OPEN });
      await dispatch("connectToServer");
    },
    async connectToServer({ commit, rootState, state, dispatch }) {
      const callBacks = await dispatch("getCallBacks");
      const connection = Connection.getInstance(callBacks);

      return new Promise((resolve, reject) => {
        (async () => {
          let joined = await connection.join();
          await connection.saveAuthorization(joined.token);
          await connection.connect(joined.room);
          await commit("setRoomId", joined.room);
          resolve();
        })();
      });
    },
    async fetchMessagesFromServer({ commit, rootState, state, dispatch }) {
      if (rootState.ticket.previousTicket) {
        const lastIndex = _.findLastIndex(
          rootState.messagesHandler.messages,
          m => m.from != "user"
        );
        commit("disabledChoices", { index: lastIndex });

        const botText = `${rootState.ticket.previousTicket.rangeKey}_${rootState.ticket.previousTicket.partitionKey}`;
        const botTicket = `<${TICKET_SEND_TAG.BOT}:${botText}>`;
        const operatorTicket = `<${TICKET_SEND_TAG.OPERATOR}:${rootState.ticket.ticket.rangeKey}_${rootState.ticket.ticket.partitionKey}>`;

        const text = `質問ID: ${botText}`;
        const initMessage = {
          from: "user",
          content: { text: text }
        };
        commit("addMessage", initMessage);

        const serverText = `${botTicket}\n${operatorTicket}`;
        await dispatch("sendMessageToServer", { text: serverText });
      }
      const callBacks = await dispatch("getCallBacks");
      const connection = Connection.getInstance(callBacks);
      let messages = await connection.fetch(state.roomId);
      await dispatch("processServerMessages", messages);
    },
    async sendMessageToServer({ commit, rootState, state, dispatch }, text) {
      const callBacks = await dispatch("getCallBacks");
      const connection = Connection.getInstance(callBacks);
      await connection.sendOperator(text).then(() => {
        const updateContent = {
          index: rootState.messagesHandler.messages.length - 1,
          result: "sent"
        };
        commit("updateUserMessage", updateContent);
      });
    },
    async sendFeedbackToServer(
      { commit, rootState, state, dispatch },
      feedback
    ) {
      const feedbackTicket = {
        mode: "operator",
        status: TICKET_STATUS.ANSWERED,
        feedback:
          feedback.keyName === "complete"
            ? FEEDBACK_STATUS.RESOLVED
            : FEEDBACK_STATUS.UNRESOLVED
      };
      dispatch("postTicket", feedbackTicket);

      const messageContent = {
        text: `${rootState.constObj.OPERATOR_FEEDBACK_SIGH} ${feedback.choice}`,
        isLast: true
      };
      await dispatch("sendMessageToServer", messageContent);
    },
    async cancellMessageToServer(
      { commit, rootState, state, dispatch },
      cancellState
    ) {
      let cancellConst;
      switch (cancellState.status) {
        case "request":
          cancellConst = rootState.constObj.CANCELL_MESSEGE_TO_SERVER;
          break;
        case "sending":
          cancellConst = rootState.constObj.CANCELL_SENDING_MESSEGE_TO_SERVER;
          break;
        case "sending_error":
          cancellConst = rootState.constObj.ERROR_SENDING_MESSEGE_TO_SERVER;
          break;
      }

      const updateContent = {
        index: rootState.messagesHandler.messages.length - 1,
        result: cancellState.status
      };
      await commit("updateUserMessage", updateContent);

      const cancellText = `${cancellConst}${cancellState.text}`;
      const cancellMessage = {
        from: "operator",
        content: { text: cancellText },
        status: "sent"
      };
      await commit("addMessage", cancellMessage);
    },
    async abortConnection({ commit, rootState, state, dispatch }) {
      await dispatch("disConnection");
      dispatch("postTicket", { mode: "operator", status: TICKET_STATUS.QUIT });

      const disconnectMessage = {
        from: "operator",
        content: { text: rootState.constObj.DISCONNECT_MESSAGE },
        status: TICKET_STATUS.QUIT
      };
      commit("addMessage", disconnectMessage);
    },
    async disConnection({ commit, rootState, state, dispatch }) {
      const callBacks = await dispatch("getCallBacks");
      const connection = Connection.getInstance(callBacks);
      await connection.disConnect();
    },
    async reConnection({ commit, rootState, state, dispatch }) {
      if (state.connectStatus === CONNECT_STATUS.UNCONNECTED) {
        return;
      }
      if (state.roomId) {
        await dispatch("connectToServer");
      } else {
        const callBacks = await dispatch("getCallBacks");
        const connection = Connection.getInstance(callBacks);
        await connection.connect(state.roomId);
      }
    },
    getCallBacks({ dispatch, commit }) {
      const callBacks = {
        connect: () => dispatch("fetchMessagesFromServer"),
        message: messages => dispatch("processServerMessages", messages),
        cancell: text => dispatch("cancellMessageToServer", text),
        initTicketAuth: () => dispatch("initPreviousTicket"),
        setStatus: status => commit("setConnectStatus", status),
        setIsSending: boolean => commit("setIsSending", boolean),
        setRoomId: roomId => commit("setRoomId", roomId)
      };
      return callBacks;
    }
  }
};
