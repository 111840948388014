import Vue from "vue";
import App from "@/App";
import router from "@/router";
import defaultComponents from "@/components";
import AsyncComputed from "vue-async-computed-non-debounce";
import store from "@/store/index";
import VueScrollTo from "vue-scrollto";

Vue.use(AsyncComputed);

Vue.config.productionTip = false;

export const init = ({ components, config }) => {
  const mergedComponents = Object.assign({}, defaultComponents, components);
  for (const componentName of Object.keys(mergedComponents)) {
    Vue.component(componentName, mergedComponents[componentName]);
  }

  const rootElementId = "app-ai-chat-window";
  const rootElement = document.createElement("div");
  rootElement.id = rootElementId;
  document.body.appendChild(rootElement);

  new Vue({
    router,
    store,
    el: `#${rootElementId}`,
    components: { App },
    template: "<App/>"
  });
};

export const domReady = callback => {
  if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
  ) {
    callback();
  } else {
    document.addEventListener("DOMContentLoaded", callback);
  }
};
