"use strict";
import io from "socket.io-client";
import Cookie from "js-cookie";
import "whatwg-fetch";

const CONNECT_STATUS = {
  REQUEST: "request",
  CONNECTED: "connected",
  UNCONNECTED: "unconnected"
};

let instance = null;
export class Connection {
  static getInstance(params) {
    if (instance) {
      return instance;
    }
    instance = new Connection(params);
    return instance;
  }

  static onSalesTime(constObj) {
    if (constObj.CUSTOM_SALES_TIME_LIST) {
      return Connection.onCustomSalesTime(
        constObj.CUSTOM_SALES_TIME_LIST,
        new Date()
      );
    }

    const startTime = constObj.SALES_TIME.START_TIME;
    const endTime = constObj.SALES_TIME.END_TIME;

    const now = new Date();
    const hour = ("00" + now.getHours()).slice(-2);
    const minute = ("00" + now.getMinutes()).slice(-2);
    const nowTime = hour + minute;

    if (startTime < nowTime && nowTime < endTime) {
      return true;
    } else {
      return false;
    }
  }

  static onCustomSalesTime(salesTimes, currentTime) {
    const salesTime = salesTimes.find(({ end }) => currentTime < new Date(end));
    return !salesTime || currentTime < new Date(salesTime.start);
  }

  constructor(params) {
    this.productId = ENV_SETTINGS.productId;
    this.apiUrl = ENV_SETTINGS.apiUrl;
    this.retryMax = 30;
    this.retryCount = 0;
    this.retryInterval = 5000;
    this.sendRetryMax = 3;
    this.sendRetryCount = 0;
    this.sendRetryInterval = 3000;
    this._isChatSending = false;
    this._connectStatus = CONNECT_STATUS.UNCONNECTED;
    this._roomId = "";
    this._connect = params.connect;
    this._message = params.message;
    this._cancell = params.cancell;
    this._setStatus = params.setStatus;
    this._setIsSending = params.setIsSending;
    this._setRoomId = params.setRoomId;
    this._initTicketAuth = params.initTicketAuth;
  }

  join() {
    return this.jsonRequest(
      `${this.apiUrl}/product/${this.productId}/ai_chat_window/join`,
      {
        method: "POST"
      }
    )
      .then(res => {
        return res.status >= 400 ? Promise.reject(res.status) : res.json();
      })
      .then(json => {
        return Promise.resolve(json);
      });
  }

  jsonRequest(url, params) {
    let auth = this.detectAuthorization();
    let headers = Object.assign(
      auth ? { authorization: auth } : {},
      params.headers
    );
    headers["Content-Type"] = "application/json";
    params.headers = headers;
    return fetch(url, params);
  }

  detectAuthorization() {
    return Cookie.get(`sci-ai-chat-window-${this.productId}`);
  }

  saveAuthorization(auth) {
    Cookie.set(`sci-ai-chat-window-${this.productId}`, auth);
  }

  fetch(roomId) {
    return this.jsonRequest(
      `${this.apiUrl}/product/${this.productId}/ai_chat_window/room/${roomId}/fetch`,
      {}
    ).then(res => {
      return res.status >= 400 ? Promise.reject(res.status) : res.json();
    });
  }

  connect(roomId) {
    this._roomId = roomId;
    this._setRoomId(this._roomId);
    this._connectStatus = CONNECT_STATUS.REQUEST;
    this._setStatus(this._connectStatus);
    return new Promise((resolve, reject) => {
      let auth = this.detectAuthorization();
      this.socket = io(`${this.apiUrl}/ai-chat-window`, {
        transports: ["websocket", "polling"],
        autoConnect: false,
        query: { token: auth, room: roomId },
        reconnection: true,
        reconnectionDelay: 6000,
        timeout: 30000
      });
      this.setupSocket();
      this.socket.connect();
      resolve();
    });
  }

  setupSocket() {
    this.socket.on("dong", () => {
      console.log("dong");
      this.latency = Date.now() - this.startPingTime;
    });

    this.socket.on("connect_failed", () => {
      console.log("connect_failed");
      this.disConnect();
    });

    this.socket.on("connect_error", () => {
      console.log("connect_error");
      this.reConnect();
    });

    this.socket.on("disconnect", a => {
      console.log("disconnect");
      if (this._connectStatus === CONNECT_STATUS.CONNECTED) {
        this.reConnect();
      }
    });

    this.socket.on("userDisconnect", () => {
      console.log("userDisconnect");
    });

    this.socket.on("message", data => {
      console.log("message");
      this._message(data);
    });

    this.socket.on("connect", data => {
      console.log("connect");
      if (this._connectStatus === CONNECT_STATUS.UNCONNECTED) {
        this.socket.close();
        return;
      }
      if (this.socket.connected) {
        this._connectStatus = CONNECT_STATUS.CONNECTED;
        this._setStatus(this._connectStatus);
        this._connect();
        this.retryCount = 0;
      } else {
        this.reConnect();
      }
    });

    this.socket.on("response", () => {
      console.log("response");
    });
  }

  reConnect() {
    const doReConnect = () => {
      this.retryCount++;
      if (this.retryCount < this.retryMax) {
        this.socket.connect();
      } else {
        this.socket.disConnect();
      }
    };
    if (this.reConnectId != null) {
      clearTimeout(this.reConnectId);
    }
    this.reConnectId = setTimeout(() => {
      doReConnect();
    }, this.retryInterval);
  }

  sendOperator(userMessage) {
    const messageForServer = {
      user_id: this._roomId,
      text: userMessage.text,
      type: "text"
    };

    if (this._isChatSending) {
      console.warn("now sending.\nsendChat is cancelled.");
      console.warn(messageForServer);
      const cancellState = { text: messageForServer.text, status: "sending" };
      this._cancell(cancellState);
      return Promise.resolve();
    }

    this._isChatSending = true;
    this._setIsSending(this._isChatSending);

    return new Promise((resolve, reject) => {
      this.socket.emit("message", messageForServer, response => {
        if (!response.id) {
          const errorState = {
            text: messageForServer.text,
            status: "sending_error"
          };
          this._cancell(errorState);
          this._isChatSending = false;
          this._setIsSending(this._isChatSending);
          this.resend(userMessage);
          resolve();
        } else {
          this._initTicketAuth();
          if (userMessage.isLast) {
            this.disConnect();
          } else {
            this._isChatSending = false;
            this._setIsSending(this._isChatSending);
          }
          resolve();
        }
      });
    });
  }

  resend(userMessage) {
    const doSend = () => {
      this.SendRetryCount++;
      if (this.sendRetryCount < this.sendRetryMax) {
        this.sendOperator(userMessage);
      } else {
        const errorState = {
          text: userMessage.text,
          status: "sending_error"
        };
        this._cancell(errorState);
      }
    };
    if (this.reSendId != null) {
      clearTimeout(this.reSendId);
      this.sendRetryCount = 0;
    }
    this.reSendId = setTimeout(() => {
      doSend();
    }, this.sendRetryInterval);
  }

  disConnect() {
    this._connectStatus = CONNECT_STATUS.UNCONNECTED;
    this._setStatus(this._connectStatus);
    this._isChatSending = false;
    this._setIsSending(this._isChatSending);
    this._roomId = "";
    this._setRoomId(this._roomId);
    this.retryCount = 0;
    this.sendRetryCount = 0;
    if (this.socket) {
      this.socket.close();
    }
  }
}
