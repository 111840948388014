var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "saichat-suggestion" }, [
    _c(
      "ul",
      _vm._l(_vm.userInputChoices, function(choice, key) {
        return _c(
          "li",
          { key: key, staticClass: "saichat-suggestion__choice" },
          [
            _c("a", {
              staticClass: "saichat-suggestion__choice__link",
              domProps: { innerHTML: _vm._s(choice) },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.selectSuggest(choice, key)
                }
              }
            })
          ]
        )
      })
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }