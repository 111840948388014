const importAll = context => {
  const modules = {};
  for (const key of context.keys()) {
    const match = key.match(/\/([a-zA-Z0-9_-]+).vue$/);
    if (match) {
      const name = match[1];
      modules[name] = context(key).default;
    }
  }
  return modules;
};
const webPackContext = require.context("./", true, /\/([a-zA-Z0-9_-]+).vue$/);
const modules = importAll(webPackContext);

export default modules;
