"use strict";
const co = require("co");
const TextProcessor = require("../libs/text-processor");

class StateMachine {
  constructor(config, options = null) {
    this.stepId = null;
    this.stepHistory = [];
    this.step = {};
    this.customFunctions = {};
    if (options && options.customFunctions) {
      this.customFunctions = options.customFunctions;
    }
    if (config) {
      this._init(config);
    }
  }

  _init(config) {
    for (let s in config.scenario.step) {
      let stepData = config.scenario.step[s];
      stepData.id = s;
      let step = new Step(stepData);
      for (let actionLabel in stepData.action) {
        stepData.action[actionLabel].key = actionLabel;
        let action = this.createAction(stepData.action[actionLabel]);
        if (action) {
          step.addAction(action);
        }
      }
      this.addStep(step);
    }

    const { root, any } = config.scenario.flow;
    this._traverseScenario(new Flow(root), root);
    this._addAnyStateTransitions(any);

    return;
  }

  createAction(a) {
    switch (a.type) {
      case "text":
        return new TextAction(a);
      case "button":
        return new ButtonAction(a);
      case "imagemap":
        return new ImageMapAction(a);
      case "custom":
        if (a.value in this.customFunctions) {
          let action = new CustomAction(a);
          action.setCustomFunction(this.customFunctions[a.value]);
          return action;
        }
      default:
        return;
    }
  }

  setStepId(step) {
    this.stepId = step;
  }

  _traverseScenario(flow, flowData) {
    // this.flows[f.step] = f;
    if (flowData.next && flowData.next.length > 0) {
      flowData.next.forEach(nextFlowData => {
        const nextFlow = new Flow(nextFlowData);
        if (
          nextFlowData.condition.type == "function" &&
          nextFlowData.condition.value in this.customFunctions
        ) {
          nextFlow.setConditionFunction(
            this.customFunctions[nextFlow.condition.value]
          );
        }
        flow.addNext(nextFlow);
        this._traverseScenario(nextFlow, nextFlowData);
      });
    }

    if (flow.step && this.step[flow.step]) {
      const existingFlow = this.step[flow.step].getFlow();
      const toBeReplaced = !existingFlow || !existingFlow.link;

      if (flow.step in this.step && toBeReplaced) {
        //if ((flow.step in this.step) && !this.step[flow.step].getFlow()){
        this.step[flow.step].setFlow(flow);
      }
    }
  }

  _addAnyStateTransitions(flowData) {
    if (flowData) {
      for (let nextFlowData of flowData.next) {
        for (let s in this.step) {
          const nextFlow = new Flow(nextFlowData);
          if (
            nextFlowData.condition.type == "function" &&
            nextFlowData.condition.value in this.customFunctions
          ) {
            nextFlow.setConditionFunction(
              this.customFunctions[nextFlow.condition.value]
            );
          }
          this.step[s].flow.addNext(nextFlow);
        }
      }
    }
  }

  addStep(step) {
    //if (this.step.map(o=>{return o.id}).indexOf(state.id)!==-1) { return false; }
    if (step.id in this.step) {
      return false;
    }

    this.step[step.id] = step;
    return true;
  }

  _isNextStepValid(step) {
    //if (this.stepHistory.map(o=>{return o.id}).indexOf(state.id)!==-1) { return false; }
    return step in this.step && step !== this.stepId.id;
  }

  setStepIdById(stepId) {
    if (!(stepId in this.step)) {
      return false;
    }
    this.stepId = this.step[stepId];
    return true;
  }

  setStepId(step) {
    this.stepId = step;
  }

  getStepId() {
    return this.stepId;
  }

  getStepById(stepId) {
    return stepId in this.step ? this.step[stepId] : null;
  }

  isLastStep(step) {
    return this.getStepById(step.stepId).getFlow().next.length > 0;
  }

  proceed(input, external = null) {
    const _this = this;
    return co(function*() {
      const nextStepCandidate = yield _this.stepId.selectNextStep(
        input,
        external
      );
      const ret = yield _this.moveToNextStep(nextStepCandidate, external);
      return ret;
    }).catch(function(error) {
      console.log(error);
    });
  }

  moveToNextStep(nextStepCandidate, external = null) {
    const _this = this;
    return new Promise(resolve => {
      let ret = { stepId: nextStepCandidate };
      if (_this._isNextStepValid(nextStepCandidate)) {
        ret.state = "success";
        _this.stepId = _this.step[nextStepCandidate];
        if ("success" in _this.stepId.actions) {
          _this.stepId.actions.success.do(external).then(d => {
            ret.data = d;
            resolve(ret);
          });
        } else {
          resolve(ret);
        }
      } else {
        ret.state = "error";
        if ("error" in _this.stepId.actions) {
          _this.stepId.actions.error.do(external).then(d => {
            ret.data = d;
            resolve(ret);
          });
        } else {
          resolve(ret);
        }
      }
    }).catch(function(error) {
      console.log(error);
    });
  }
}

class Step {
  constructor(s) {
    this.id = s.id;
    this.flow = null;
    this.actions = {};
    this.threthold = 0.5;
  }

  selectNextStep(input, external) {
    const _this = this;
    return co(function*() {
      const mappers = _this.flow.next.map(next_f => {
        return new Promise(resolve => {
          let score = 0;
          switch (next_f.condition.type) {
            case "function":
              resolve(next_f.executeConditionFunction(input, external));
              break;
            case "number":
              resolve(next_f.detectNumber(input));
              break;
            case "any":
              resolve(1.0);
            case "and":
            case "or":
            default:
              resolve(next_f.calcScore(input));
          }
        });
      });

      const scores = yield Promise.all(mappers);
      const nextStepCandidate = _this._reduce(scores);
      return nextStepCandidate;
    });
  }

  _reduce(scores) {
    const { flow, id, threthold } = this;
    const maxValue = Math.max.apply(null, scores);
    const maxIndex = scores.indexOf(maxValue);
    if (maxValue > threthold) {
      return flow.next[maxIndex].step;
    } else {
      return id;
    }
  }

  addAction(a) {
    this.actions[a.key] = a;
  }

  setFlow(flow) {
    this.flow = flow;
  }

  getFlow() {
    return this.flow;
  }
}

class Flow {
  constructor(f) {
    this.condition = f.condition;
    this.step = f.step;
    this.next = [];
    this.conditionFunction = null;
  }

  calcScore(input) {
    return new Promise(r => {
      r(
        input.search(new RegExp(`(${this.condition.value})$`, "gmi")) == 0
          ? 1.0
          : 0.0
      );
    }).catch(function(error) {
      console.log(error);
    });
  }

  detectNumber(input) {
    const num = TextProcessor.numberDetector(input);
    if (parseInt(this.condition.value) == num) {
      return 1.0;
    }
    return 0.0;
  }

  getNextState() {
    return this.step;
  }

  addNext(f) {
    this.next.push(f);
  }

  setConditionFunction(cf) {
    this.conditionFunction = cf;
  }

  executeConditionFunction(input, external = null) {
    return new Promise(resolve => {
      if (typeof this.conditionFunction === "function") {
        if (
          this.conditionFunction.then &&
          typeof this.conditionFunction.then === "function"
        ) {
          this.conditionFunction(input, external).then(ret => {
            resolve(ret);
          });
        } else {
          const ret = this.conditionFunction(input, external);
          resolve(ret);
        }
      } else {
        return resolve(null);
      }
    }).catch(function(error) {
      console.log(error);
    });
  }
}

class Action {
  constructor(a) {
    this.type = a.type;
    this.key = a.key;
  }
  do(external = null) {}
}

class TextAction extends Action {
  constructor(a) {
    super(a);
    this.value = a.value;
    this.items = a.items;
    this.option = a.option;
  }
  do(external = null) {
    return new Promise(resolve => {
      const ret = {
        type: "text",
        text: this.value,
        items: this.items,
        option: this.option
      };
      resolve(ret);
    }).catch(function(error) {
      console.log(error);
    });
  }
}

class CustomAction extends Action {
  constructor(a) {
    super(a);
  }
  setCustomFunction(f) {
    this.customFunction = f;
  }
  do(external = null) {
    if (
      this.customFunction.then &&
      typeof this.customFunction.then === "function"
    ) {
      return this.customFunction(external);
    } else {
      return new Promise(r => {
        const ret = this.customFunction(external);
        r(ret);
      }).catch(function(error) {
        console.log(error);
      });
    }
  }
}

module.exports = {
  StateMachine: StateMachine
};
