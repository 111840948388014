import { eventBus } from "@/eventBus";

export default {
  props: ["userInputChoices"],
  methods: {
    selectSuggest(choice, key) {
      this.$emit("updateSuggestionParams", choice, key);
      this.$emit("selectSuggest", key);
      eventBus.$emit("clearInputMessage");
      this.$emit("clearSuggestion");
    },
  }
};
