import _ from "underscore";
import textParser from "common/libs/textParser";
import { ScriptMatchingManager } from "common/libs/qaRetrieval";

const ScriptMatchingManagerFactory = {
  _instance: null,
  create(resources) {
    if (!this._instance) {
      this._instance = new ScriptMatchingManager({
        matchingScript: resources.script,
        invertedIndex: resources.inverted_index,
        synonymDict: resources.synonym_dict,
        invPostProbDist: resources.script_by_id,
        categoryData: resources.talk_script.body
      });
    }
    return this._instance;
  }
};

function getMapById(resources) {
  const talkScript = resources ? resources.talk_script.body : "";
  return _.indexBy(talkScript, item => {
    return item.id;
  });
}

function getSync(mapById, id) {
  return mapById && mapById[id];
}

function getSyncWithAncesters(mapById, id) {
  const target = getSync(mapById, id);
  if (target && !target.ancesters) {
    target.ancesters = [];
    for (
      let current = getSync(mapById, target.parent);
      current;
      current = getSync(mapById, current.parent)
    ) {
      target.ancesters.unshift(current);
    }
  }
  return target;
}

function cleanText(text) {
  return (
    text &&
    text
      .replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      })
      .toLowerCase()
  );
}

function getChoicesItems(text, resources, choicesLimit) {
  const scriptMatchingManager = ScriptMatchingManagerFactory.create(resources);
  const ids = scriptMatchingManager.getSearchResult(cleanText(text));
  const mapById = getMapById(resources);
  let items = ids.map(id => {
    return getSyncWithAncesters(mapById, id);
  });

  return items.slice(0, choicesLimit);
}

function containResetTriggerMessages(text, triggerMessages) {
  return _.contains(triggerMessages, text);
}

function searchRepresentativeWord(synonymDict, text) {
  let result = "";
  for (let key of Object.keys(synonymDict)) {
    if (text.toUpperCase() == key.toUpperCase()) {
      result = synonymDict[key];
      break;
    }
  }
  return result;
}

function createVocabularyList(synonymDict, wordList) {
  const result = [];
  wordList.forEach(element => {
    if (element) {
      const representativeWord = searchRepresentativeWord(synonymDict, element);
      if (representativeWord) result.push(representativeWord);
    }
  });
  return result;
}
export default {
  mutations: {
    setSearchingMessage(rootState, payload) {
      rootState.searchingMessage = payload.searchingMessage;
    },
    setSearchResults(rootState, payload) {
      rootState.searchResults = payload;
    }
  },
  getters: {
    searchResults(state) {
      return state.searchResults;
    }
  },
  actions: {
    searchAutoComplete({ commit, getters, dispatch, rootState }, text) {
      const items = getChoicesItems(
        text,
        rootState.resources,
        rootState.constObj.CHOICES_LIMIT
      );

      const choices = {};
      items
        .filter(e => e.items.scenario_id)
        .forEach(e => {
          choices[e.items.scenario_id] = e.text;
        });

      return choices;
    },
    searchSynonym({ commit, getters, dispatch, rootState }, text) {
      let inputWordList = text.split(/\s+/);
      const synonymDict = rootState.resources.synonym_dict;
      const vocabularyList = createVocabularyList(synonymDict, inputWordList);

      vocabularyList.forEach(element => {
        const toUpperCaseElement = element.toUpperCase();
        for (let key of Object.keys(synonymDict)) {
          if (toUpperCaseElement == synonymDict[key].toUpperCase())
            inputWordList.push(key);
        }
      });
      const result = inputWordList
        .filter(function(x, i, self) {
          return self.indexOf(x) === i;
        })
        .filter(v => v);
      return result;
    },
    isGreetingMessages({ commit, state, rootState, dispatch }, text) {
      const greetKeys = Object.keys(rootState.constObj.GREETING_CHAT);
      return _.contains(greetKeys, text);
    },
    searchGreetingMessage({ commit, getters, dispatch, rootState }, text) {
      const answers = rootState.constObj.GREETING_CHAT[text];
      const index = Math.floor(Math.random() * answers.length);
      return answers[index];
    },
    searchMessage({ commit, getters, dispatch, rootState }, text) {
      if (!text) return;

      if (
        containResetTriggerMessages(
          text,
          rootState.constObj.RESET_TRIGGER_MESSAGES
        )
      ) {
        dispatch("resetMessages", null, { root: true });
        return;
      }

      commit("setSearchingMessage", { searchingMessage: text }, { root: true });

      if (!this.getters.searchEnabled) {
        dispatch("addInterruptionMessage", null, { root: true });
        return;
      }

      const searchResult = getChoicesItems(
        text,
        rootState.resources,
        rootState.constObj.CHOICES_LIMIT
      );

      const searchResultItems = {
        results: searchResult.map(e => e.items.scenario_id),
        query: text
      };
      commit("setSearchResults", searchResult);
      dispatch("updateTicketItems", searchResultItems);
      dispatch("addSearchResultMessage", searchResult);
    }
  }
};
