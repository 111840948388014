var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "messagesContent", attrs: { id: "saichat-content" } },
    [
      _vm.showCopyright
        ? _c("div", {
            staticClass: "saichat-copyright",
            domProps: { innerHTML: _vm._s(_vm.copyright) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.messages, function(message, index) {
        return _c("MessageArea", {
          key: index,
          attrs: { message: message, index: index },
          on: { selectAnswer: _vm.selectAnswer }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }