import { StateMachine } from "common/libs/dialog-handler";
import textParser from "common/libs/textParser";
import _ from "underscore";

const STEP_STATUS = {
  INIT: "init",
  SCRIPT_NOT_FOUND: "script_not_found",
  SEARCH_FAILED: "search_failed",
  LAST_STEP: "last_step",
  IN_SCENARIO: "in_scenario",
  FEEDBACK: "feedback",
  READY_CONNECT: "ready_connect",
  INTERRUPTION: "interruption"
};

const TICKET_STATUS = {
  OPEN: "open",
  SCRIPT_NOT_FOUND: "scriptNotFound",
  SEARCH_FAILED: "searchFailed",
  RESEARCH: "re-search",
  ANSWERED: "answered",
  QUIT: "quit",
  RESOLVED: "resolved",
  UNRESOLVED: "unresolved"
};

const FEEDBACK_STATUS = {
  RESOLVED: "resolved",
  UNRESOLVED: "unresolved"
};

class BotActions {
  constructor({ commit, rootState, state, dispatch }, keyName) {
    this.commit = commit;
    this.rootState = rootState;
    this.state = state;
    this.dispatch = dispatch;
    this.keyName = keyName;
  }

  setNewState(stateParams) {
    this.commit("setStepId", { stepId: stateParams.stepId });
    this.commit("setStepStatus", { stepStatus: stateParams.stepStatus });
  }

  doStateTransition() {}

  doActionMutation() {}

  _createMessage() {}

  _createTicket(type) {}

  async _postTicket(ticket) {
    await this.dispatch("postTicket", ticket);
  }
  _updateTicketItems(items) {
    this.dispatch("updateTicketItems", items);
  }

  _addMessage(message) {
    this.commit("addMessage", message);
  }
  _goBackToInit() {
    this.dispatch("addInitMessage");
  }
  _goToNextAction() {
    this.dispatch("dispatchAction");
  }
}

class AddInitMessage extends BotActions {
  doStateTransition() {
    const stateParams = {
      stepId: "init",
      stepStatus: STEP_STATUS.INIT,
      transitionType: "success"
    };
    return stateParams;
  }
  doActionMutation(newState) {
    const message = this._createMessage(newState.transitionType);
    this._addMessage(message);
  }
  _createMessage(type) {
    const messageContent = {
      text: this.rootState.constObj.INIT_MESSAGE,
      choices: this.rootState.category
    };
    return { from: "bot", content: messageContent };
  }
}

class AddUserMessage extends BotActions {
  setUserMessage(text) {
    this.userMessage = text;
  }
  doActionMutation(type) {
    const message = this._createMessage(type);
    this._addMessage(message);
  }
  _createMessage(type) {
    const text = this.userMessage;
    const messageContent = { text };
    return {
      from: "user",
      status: this.state.stepStatus,
      content: messageContent
    };
  }
}

class AddSearchResultMessage extends BotActions {
  setSearchResult(searchResult) {
    this.searchResult = searchResult;
  }
  doStateTransition() {
    const isNotFound = this.searchResult.length <= 0;
    let stateParams;
    if (isNotFound) {
      stateParams = {
        stepId: "init",
        stepStatus: STEP_STATUS.SCRIPT_NOT_FOUND,
        transitionType: "error"
      };
    } else {
      stateParams = {
        stepId: "init",
        stepStatus: STEP_STATUS.INIT,
        transitionType: "success"
      };
    }
    return stateParams;
  }
  doActionMutation(type) {
    const ticket = this._createTicket(type);
    if (ticket) {
      this._postTicket(ticket);
    }
    if (ticket.status === TICKET_STATUS.SCRIPT_NOT_FOUND && this.rootState.constObj.HAS_UNRESOLVED_ENQUETE) {
      return;
    }
    const message = this._createMessage(type);
    this._addMessage(message);
  }
  _createMessage(type) {
    let messageContent;
    if (type === "success") {
      const choices = {};
      this.searchResult
        .filter(e => e.items.scenario_id)
        .forEach(e => {
          choices[e.items.scenario_id] = e.text;
        });
      Object.assign(choices, {
        other_choice: this.rootState.constObj.OTHER_CHOICE
      });
      messageContent = {
        text: this.rootState.constObj.CHOICES_TITLE,
        choices: choices
      };
    } else {
      messageContent = {
        text: this.rootState.constObj.SCRIPT_NOT_FOUND_MESSAGE
      };
    }
    return { from: "bot", content: messageContent };
  }
  _createTicket(type) {
    let ticketContent;
    if (type === "success") {
      ticketContent = {
        status: TICKET_STATUS.OPEN,
        mode: "bot"
      };
    } else {
      ticketContent = {
        status: TICKET_STATUS.SCRIPT_NOT_FOUND,
        mode: "bot"
      };
      if (this.rootState.constObj.HAS_UNRESOLVED_ENQUETE) {
        this.dispatch("openEnquete", "bot");
      }
    }
    return ticketContent;
  }
}

class AddBotMessage extends BotActions {
  async doStateTransition(keyName) {
    let stateParams;
    if (keyName === "other_choice") {
      stateParams = {
        stepId: this.state.stepId,
        stepStatus: this.rootState.constObj.HAS_OPERATOR
          ? STEP_STATUS.READY_CONNECT
          : STEP_STATUS.INIT,
        transitionType: "error"
      };
    } else {
      const options = { customFunctions: {} };
      const stateMachine = new StateMachine(this.rootState.resources, options);
      stateMachine.setStepIdById(this.state.stepId);
      this.newState = await stateMachine.proceed(keyName, {});
      const hasNextStep = stateMachine.isLastStep(this.newState);

      stateParams = {
        stepId: this.newState.stepId,
        stepStatus: hasNextStep
          ? STEP_STATUS.IN_SCENARIO
          : STEP_STATUS.LAST_STEP,
        transitionType: "success"
      };
    }
    return stateParams;
  }

  _createMessage(type) {
    if (type === "success") {
      return {
        from: "bot",
        content: textParser.parseButton(this.newState.data.text),
        status: this.state.stepStatus
      };
    }

    if (this.state.stepStatus === STEP_STATUS.READY_CONNECT) {
      return {
        from: "bot",
        content: {
          text: this.rootState.constObj.OTHER_CHOICE_MESSAGE,
          choices: this.rootState.constObj.CONNECT_OPERATOR_CHOICES
        },
        status: this.state.stepStatus
      };
    } else {
      return {
        from: "bot",
        content: { text: this.rootState.constObj.OTHER_CHOICE_MESSAGE },
        status: this.state.stepStatus
      };
    }
  }

  doActionMutation(type) {
    const ticket = this._createTicket(type);
    if (ticket) {
      this._postTicket(ticket);
    }
    if ((ticket.status !== TICKET_STATUS.SEARCH_FAILED) || (ticket.status === TICKET_STATUS.SEARCH_FAILED && !this.rootState.constObj.HAS_UNRESOLVED_ENQUETE)) {
      const message = this._createMessage(type);
      this._addMessage(message);
    }
    if (this.state.stepStatus === STEP_STATUS.LAST_STEP && type === "success") {
      this._goToNextAction();
    }
  }

  _createTicket(type) {
    let ticketContent;
    if (type === "success") {
      ticketContent = {
        status:
          this.state.stepStatus === STEP_STATUS.LAST_STEP
            ? TICKET_STATUS.ANSWERED
            : TICKET_STATUS.OPEN,
        mode: "bot"
      };

      this._updateTicketItems(this.newState.data.items);
    } else {
      ticketContent = {
        status: TICKET_STATUS.SEARCH_FAILED,
        mode: "bot"
      };
      if (this.rootState.constObj.HAS_UNRESOLVED_ENQUETE) {
        this.dispatch("openEnquete", "bot");
      }
    }

    return ticketContent;
  }
}

class AddAskFeedbackMessage extends BotActions {
  doStateTransition() {
    const stateParams = {
      stepId: this.state.stepId,
      stepStatus: STEP_STATUS.FEEDBACK,
      transitionType: "success"
    };
    return stateParams;
  }
  _createMessage(type) {
    const messageContent = {
      text: this.rootState.constObj.ASK_FEEDBACK_MESSAGE,
      choices: this.rootState.constObj.ASK_FEEDBACK_CHOICES
    };
    return {
      from: "bot",
      status: STEP_STATUS.FEEDBACK,
      content: messageContent
    };
  }
  doActionMutation(type) {
    const message = this._createMessage(type);
    this._addMessage(message);
  }
}

class AddFinalMessage extends BotActions {
  doStateTransition() {
    const stateParams = {
      stepId: "init",
      stepStatus: STEP_STATUS.INIT,
      transitionType: "success"
    };
    return stateParams;
  }
  _createMessage(type) {
    const messageContent = { text: this.rootState.constObj.FINAL_MESSAGE };
    return {
      from: "bot",
      status: STEP_STATUS.FEEDBACK,
      content: messageContent
    };
  }
  doActionMutation(type) {
    const message = this._createMessage(type);
    this._addMessage(message);
  }
}

class AddFeedbackDoneMessage extends BotActions {
  doStateTransition() {
    const newStatus =
      this.rootState.constObj.HAS_OPERATOR && this.keyName != "complete"
        ? STEP_STATUS.READY_CONNECT
        : STEP_STATUS.INIT;
    const stateParams = {
      stepId: STEP_STATUS.INIT,
      stepStatus: newStatus,
      transitionType: this.keyName === "complete" ? "success" : "error"
    };

    return stateParams;
  }

  _createMessage(type) {
    if (type === "success") {
      return {
        from: "bot",
        content: { text: this.rootState.constObj.FEEDBACK_DONE_MESSAGE }
      };
    }

    if (this.state.stepStatus === STEP_STATUS.READY_CONNECT) {
      return {
        from: "bot",
        content: {
          // 有人機能ありルート
          // 未解決メッセージをそのまま表示
          text: this.rootState.constObj.FEEDBACK_ERROR_MESSAGE,
          choices: this.rootState.constObj.CONNECT_OPERATOR_CHOICES
        }
      };
    } else {
      this.dispatch("setTicketContents");
      return {
        from: "bot",
        content: { text: this.rootState.constObj.REFLECTED_FEEDBACK_ERROR_MESSAGE }
      };
    }
  }

  _createTicket(type) {
    let ticketContent;
    if (type === "success") {
      ticketContent = {
        status: TICKET_STATUS.ANSWERED,
        feedback: FEEDBACK_STATUS.RESOLVED,
        mode: "bot"
      };
    } else {
      ticketContent = {
        status: TICKET_STATUS.ANSWERED,
        feedback: FEEDBACK_STATUS.UNRESOLVED,
        mode: "bot"
      };
      if (this.rootState.constObj.HAS_UNRESOLVED_ENQUETE) {
        this.dispatch("openEnquete", "bot");
      }
    }
    return ticketContent;
  }

  doActionMutation(type) {
    const ticket = this._createTicket(type);
    if (ticket) {
      this._postTicket(ticket);
    }
    const constObj = this.rootState.constObj
    if (ticket.feedback === FEEDBACK_STATUS.RESOLVED && constObj.HAS_RESOLVED_ENQUETE) {
      return;
    }
    if (ticket.feedback === FEEDBACK_STATUS.UNRESOLVED && constObj.HAS_UNRESOLVED_ENQUETE) {
      return;
    }
    const message = this._createMessage(type);
    this._addMessage(message);
  }
}

class AddInterruptionMessage extends BotActions {
  doStateTransition() {
    const stateParams = {
      stepId: this.state.stepId,
      stepStatus: STEP_STATUS.INTERRUPTION,
      transitionType: "success"
    };
    return stateParams;
  }
  doActionMutation() {
    const lastIndex = _.findLastIndex(this.state.messages, { from: "bot" });
    this.commit("disabledChoices", { index: lastIndex });
    const message = this._createMessage();
    this._addMessage(message);
  }
  _createMessage(type) {
    const messageContent = {
      text: this.rootState.constObj.INTERRUPTION_MESSAGE,
      choices: this.rootState.constObj.INTERRUPTION_CHOICES
    };
    return {
      from: "bot",
      content: messageContent,
      status: STEP_STATUS.INTERRUPTION
    };
  }
}

class AddBackFromInterruptionMessage extends BotActions {
  _getLastMessage() {
    const lastIndex = _.findLastIndex(
      this.rootState.messagesHandler.messages,
      message => {
        return (
          message.from === "bot" && message.status != STEP_STATUS.INTERRUPTION
        );
      }
    );
    const lastContent = this.rootState.messagesHandler.messages[lastIndex];
    return {
      from: "bot",
      content: lastContent.content,
      status: lastContent.status
    };
  }

  doStateTransition() {
    let stateParams;
    if (this.keyName == "ok") {
      stateParams = {
        stepId: "init",
        stepStatus: STEP_STATUS.INIT,
        transitionType: "forward"
      };
    } else {
      this.lastMessage = this._getLastMessage();
      stateParams = {
        stepId: this.state.stepId,
        stepStatus: this.lastMessage.status,
        transitionType: "back"
      };
    }
    return stateParams;
  }

  async doActionMutation(type) {
    if (type === "forward") {
      const ticket = this._createTicket();
      await this._postTicket(ticket);
      await this.dispatch(
        "searchMessage",
        this.rootState.search.searchingMessage
      );
    } else {
      const message = this._createMessage(type);
      this._addMessage(message);
      this._addMessage(this.lastMessage);
    }
  }

  _createMessage(type) {
    if (type === "back") {
      const messageContent = {
        text: this.rootState.constObj.BACK_TO_SCENARIO_MESSAGE
      };
      return { from: "bot", content: messageContent };
    }
  }

  _createTicket() {
    const ticketContent = {
      status: TICKET_STATUS.RESEARCH,
      mode: "bot"
    };
    return ticketContent;
  }
}

class AddAskConnectOperator extends BotActions {
  doStateTransition() {
    const stateParams = {
      stepId: "init",
      stepStatus: STEP_STATUS.INIT,
      transitionType: "success"
    };
    return stateParams;
  }

  _createMessage(type) {
    const messageContent = {
      text: this.rootState.constObj.NO_NEED_CONNECT_MESSAGE
    };
    return {
      from: "bot",
      content: messageContent,
      status: STEP_STATUS.LAST_STEP
    };
  }

  doActionMutation(type) {
    const message = this._createMessage(type);
    this._addMessage(message);
  }
}

class AddOperatorFeedbackDoneMessage extends BotActions {
  // このクラスはどこからも呼ばれていない
  doStateTransition() {
    const newStatus =
      this.keyName === "complete"
        ? this.rootState.constObj.FEEDBACK_DONE_MESSAGE
        : this.rootState.constObj.FEEDBACK_ERROR_MESSAGE;
    const stateParams = {
      stepId: "init",
      stepStatus: STEP_STATUS.INIT,
      transitionType: this.keyName === "complete" ? "success" : "error"
    };
    return stateParams;
  }

  _createMessage(type) {
    const messageContent = {
      text:
        this.keyName === "complete"
          ? this.rootState.constObj.FEEDBACK_DONE_MESSAGE
          : this.rootState.constObj.FEEDBACK_ERROR_MESSAGE
    };
    return { from: "bot", content: messageContent };
  }

  _createTicket(type) {
    let ticketContent;
    if (type === "success") {
      ticketContent = {
        status: TICKET_STATUS.RESOLVED,
        mode: "operator"
      };
    } else {
      ticketContent = {
        status: TICKET_STATUS.UNRESOLVED,
        mode: "operator"
      };
    }
    return ticketContent;
  }

  doActionMutation(type) {
    const message = this._createMessage(type);
    this._addMessage(message);
    const ticket = this._createTicket(type);
    if (ticket) {
      this._postTicket(ticket);
    }
    this._goBackToInit();
  }
}

export default {
  state: {
    stepId: "init",
    stepStatus: undefined,
    constObj: undefined
  },

  getters: {
    searchEnabled(state) {
      return !(
        state.stepStatus === STEP_STATUS.LAST_STEP ||
        state.stepStatus === STEP_STATUS.IN_SCENARIO ||
        state.stepStatus === STEP_STATUS.FEEDBACK ||
        state.stepStatus === STEP_STATUS.INTERRUPTION
      );
    }
  },

  mutations: {
    setStepId(state, payload) {
      state.stepId = payload.stepId;
    },
    setStepStatus(state, payload) {
      state.stepStatus = payload.stepStatus;
    }
  },

  actions: {
    async dispatchAction({ commit, rootState, state, dispatch }, keyName) {
      let action;
      let newState;

      switch (state.stepStatus) {
        case STEP_STATUS.INIT:
        case STEP_STATUS.SCRIPT_NOT_FOUND:
          action = new AddBotMessage(
            { commit, rootState, state, dispatch },
            keyName
          );
          newState = await action.doStateTransition(keyName);
          action.setNewState(newState);
          action.doActionMutation(newState.transitionType);
          break;

        case STEP_STATUS.IN_SCENARIO:
          action = new AddBotMessage(
            { commit, rootState, state, dispatch },
            keyName
          );
          newState = await action.doStateTransition(keyName);
          action.setNewState(newState);
          action.doActionMutation(newState.transitionType);
          break;

        case STEP_STATUS.LAST_STEP:
          if (rootState.constObj.HAS_FEEDBACK) {
            action = new AddAskFeedbackMessage(
              { commit, rootState, state, dispatch },
              null
            );
            newState = action.doStateTransition();
            action.setNewState(newState);
            action.doActionMutation();
          } else {
            action = new AddFinalMessage({
              commit,
              rootState,
              state,
              dispatch
            });
            newState = action.doStateTransition();
            action.setNewState(newState);
            action.doActionMutation(newState.transitionType);
          }
          break;

        case STEP_STATUS.FEEDBACK:
          action = new AddFeedbackDoneMessage(
            { commit, rootState, state, dispatch },
            keyName
          );
          newState = action.doStateTransition();
          action.setNewState(newState);
          action.doActionMutation(newState.transitionType);
          break;

        case STEP_STATUS.INTERRUPTION:
          action = new AddBackFromInterruptionMessage(
            { commit, rootState, state, dispatch },
            keyName
          );
          newState = action.doStateTransition();
          action.setNewState(newState);
          action.doActionMutation(newState.transitionType);
          break;

        case STEP_STATUS.READY_CONNECT:
          action = new AddAskConnectOperator(
            { commit, rootState, state, dispatch },
            keyName
          );
          newState = action.doStateTransition();
          action.setNewState(newState);
          action.doActionMutation(newState.transitionType);
          break;

        default:
          action = new AddInitMessage(
            { commit, rootState, state, dispatch },
            keyName
          );
          newState = action.doStateTransition();
          action.setNewState(newState);
          action.doActionMutation(newState);
      }
    },

    addInitMessage({ commit, rootState, state, dispatch }) {
      const action = new AddInitMessage({ commit, rootState, state, dispatch });
      const newState = action.doStateTransition();
      action.setNewState(newState);
      action.doActionMutation(newState.transitionType);
    },

    addUserMessage({ commit, rootState, state, dispatch }, text) {
      const action = new AddUserMessage({ commit, rootState, state, dispatch });
      action.setUserMessage(text);
      action.doActionMutation();
    },

    addInterruptionMessage({ commit, rootState, state, dispatch }) {
      const action = new AddInterruptionMessage({
        commit,
        rootState,
        state,
        dispatch
      });
      const newState = action.doStateTransition();
      action.setNewState(newState);
      action.doActionMutation(newState.transitionType);
    },

    addSearchResultMessage(
      { commit, rootState, state, dispatch },
      searchResult
    ) {
      const action = new AddSearchResultMessage({
        commit,
        rootState,
        state,
        dispatch
      });
      action.setSearchResult(searchResult);
      const newState = action.doStateTransition();
      action.setNewState(newState);
      action.doActionMutation(newState.transitionType);
    }
  }
};
