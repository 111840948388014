export default {
  parseButton(text) {
    const regexp = new RegExp(/<button:[\s\S]*?>/g);
    const buttonTags = text.match(regexp) || [];
    const choices = buttonTags.map(tag => {
      return tag.match(/<button:\d{1,3}.(.*)>/)[1];
    });

    return {
      text: text.replace(regexp, "").trim(),
      choices
    };
  },
  parseItemTexts(items) {
    const choices = {};
    items.forEach(item => {
      const faqId = item.items.log_faq;
      choices[faqId] = item.text;
    });
    return choices;
  }
};
