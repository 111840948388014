"use strict";

module.exports.DomHandler = {
  appendRawStyle: cssStr => {
    let head = document.head || document.getElementsByTagName("head")[0];
    let style = document.createElement("style");

    style.type = "text/css";
    if (style.styleSheet) {
      style.styleSheet.cssText = cssStr;
    } else {
      style.appendChild(document.createTextNode(cssStr));
    }

    head.appendChild(style);
  },
  toggleBodyScroll: (controlClass, force) => {
    if (force) {
      document.body.classList.add(controlClass);
    } else {
      document.body.classList.remove(controlClass);
    }
  },
  scrollTo: (scrollElement, to, duration, callback) => {
    function move(amount) {
      scrollElement.scrollTop = amount;
    }
    function position() {
      return scrollElement.scrollTop;
    }
    let start = position(),
      change = to - start,
      currentTime = 0,
      increment = 20;
    duration = typeof duration === "undefined" ? 500 : duration;
    let animateScroll = function() {
      // increment the time
      currentTime += increment;
      // find the value with the quadratic in-out easing function
      let val = Math.easeInOutQuad(currentTime, start, change, duration);
      // move the document.body
      move(val);
      // do the animation unless its over
      if (currentTime < duration) {
        requestAnimFrame(animateScroll);
      } else {
        if (callback && typeof callback === "function") {
          // the animation is done so lets callback
          callback();
        }
      }
    };
    animateScroll();
  },
  preventOutsideScrolling: targetElement => {
    // https://stackoverflow.com/questions/41594997/ios-10-safari-prevent-scrolling-behind-a-fixed-overlay-and-maintain-scroll-posi
    function isOverlayTotallyScrolled() {
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
      return (
        _overlay.scrollHeight - _overlay.scrollTop <= _overlay.clientHeight
      );
    }
    let _overlay = targetElement;
    let _clientY = null; // remember Y position on touch start
    _overlay.addEventListener(
      "touchstart",
      event => {
        if (event.targetTouches.length === 1) {
          // detect single touch
          _clientY = event.targetTouches[0].clientY;
        }
      },
      false
    );
    _overlay.addEventListener(
      "touchmove",
      event => {
        if (event.targetTouches.length === 1) {
          // detect single touch
          disableRubberBand(event);
        }
      },
      false
    );
    function disableRubberBand(event) {
      let clientY = event.targetTouches[0].clientY - _clientY;
      if (_overlay.scrollTop === 0 && clientY > 0) {
        // element is at the top of its scroll
        event.preventDefault();
      }
      if (isOverlayTotallyScrolled() && clientY < 0) {
        //element is at the top of its scroll
        event.preventDefault();
      }
    }
  }
};
