"use strict";
import axios from "axios";
import Hashids from "hashids";

const TICKET_STATUS = {
  OPEN: "open",
  SCRIPT_NOT_FOUND: "scriptNotFound",
  SEARCH_FAILED: "searchFailed",
  RESEARCH: "re-search",
  QUIT: "quit",
  ANSWERED: "answered",
  ESCALATED: "escalated",
  UNSUPPORTED: "unsupported",
  RESOLVED: "resolved",
  UNRESOLVED: "unresolved"
};

const INTERVAL = 1800;

class Ticket {
  constructor(cuid) {
    this.start_date = "";
    this.end_date = "";
    this.mode = "";
    this.status = "";
    this.client_user_id = cuid;
  }
}

async function processPostTicket(ticketData = {}) {
  if (
    _noNeedQuitTicket(ticketData.addParams.status, ticketData.ticket.status)
  ) {
    return _initTicketData(ticketData.ticket.client_user_id);
  }

  const exTicket = _withEnquete(ticketData.addParams)
    ? ticketData.previousTicket
    : ticketData.ticket;
  const originTicket = Object.assign(exTicket, ticketData.addParams);
  const now = new Date().getTime();

  // don't send if the start_date is out of expiration
  if (_outOfExpiration(now, originTicket.start_date)) {
    return _initTicketData(originTicket.client_user_id);
  }

  const ticketWithTimes = _setTicketTimes(now, originTicket);
  const newTicket = await _postToApi(ticketWithTimes);

  return {
    ticket: _selectTicket(newTicket),
    previousTicket: _selectPreviousTicket(newTicket, ticketData.previousTicket)
  };
}

function _noNeedQuitTicket(status, lastStatus) {
  return (
    status === TICKET_STATUS.QUIT &&
    (!lastStatus || lastStatus != TICKET_STATUS.OPEN)
  );
}

function _initTicketData(clientUserId) {
  return {
    ticket: new Ticket(clientUserId),
    previousTicket: new Ticket()
  };
}

function updateTicketItems(ticketData = {}) {
  const ticket = ticketData.ticket;
  const items = ticketData.addParams;

  for (let key in items) {
    if (Array.isArray(items[key])) {
      const item = items[key].concat();
      if (key in ticket) {
        ticket[key].push(...item);
      } else {
        ticket[key] = item;
      }
    } else {
      ticket[key] = items[key];
    }
  }
  return ticket;
}

function initPreviousTicket() {
  return new Ticket();
}

function _withEnquete(addParams) {
  return addParams.enquete;
}

function _outOfExpiration(now, startDate) {
  if (!startDate) {
    return false;
  }
  return (now - startDate) / 1000 > INTERVAL;
}

function _setTicketTimes(time, ticket) {
  ticket.start_date = ticket.start_date ? ticket.start_date : time;
  ticket.end_date = time;

  return ticket;
}

async function _postToApi(ticket) {
  const requestParams = { values: ticket };

  const response = await axios
    .post(ENV_SETTINGS.ticketUrl, requestParams)
    .catch(error => {
      console.log("Error");
    });

  if (!ticket.partitionKey) {
    ticket.rangeKey = response.data.putItem.rangeKey;
    ticket.partitionKey = response.data.putItem.partitionKey;
  }

  return ticket;
}

function _selectTicket(ticket) {
  return _isInheritedStatus(ticket)
    ? ticket
    : new Ticket(ticket.client_user_id);
}

function _isInheritedStatus(ticket) {
  return (
    ticket.status === TICKET_STATUS.OPEN ||
    (ticket.status === TICKET_STATUS.ANSWERED && !ticket.feedback)
  );
}

function _selectPreviousTicket(ticket, previousTicket) {
  if (_isOperatorInit(ticket.mode, ticket.status)) {
    return previousTicket;
  }
  return _isNextOperator(ticket) ? ticket : new Ticket();
}

function _isOperatorInit(mode, status) {
  return mode === "operator" && status === TICKET_STATUS.OPEN;
}

function _isNextOperator(ticket) {
  return (
    ticket.status === TICKET_STATUS.ANSWERED ||
    ticket.status === TICKET_STATUS.SCRIPT_NOT_FOUND && !ticket.enquete_unresolved ||
    ticket.status === TICKET_STATUS.SEARCH_FAILED ||
    ticket.status === TICKET_STATUS.ESCALATED
  );
}

export { processPostTicket, updateTicketItems, initPreviousTicket };
