var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "scichat-msg-area",
      class: _vm.fromWhom,
      attrs: { id: "message" + _vm.index }
    },
    [
      _vm.isBot
        ? _c("div", { staticClass: "scichat-msg-area__box" }, [
            _c("div", { staticClass: "scichat-msg-area__box__header" }, [
              !_vm.hasBotIcon
                ? _c("span", {
                    staticClass: "scichat-msg-area__box__header__icon"
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasBotIcon
                ? _c(
                    "span",
                    { staticClass: "scichat-msg-area__box__header__bot-icon" },
                    [_c("img", { attrs: { src: _vm.botIcon } })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "scichat-msg-area__box__header__name" },
                [_vm._v(_vm._s(_vm.botName))]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "scichat-msg-area__box__content" },
              [
                _c(
                  "div",
                  { staticClass: "scichat-msg-area__box__content__time" },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.message.displayed_time) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.splitText(), function(devidedMessage, idx) {
                  return _c(
                    "div",
                    {
                      key: "m-" + idx,
                      staticClass: "scichat-msg-area__box__content__passage"
                    },
                    [
                      _c("div", {
                        staticClass:
                          "scichat-msg-area__box__content__passage__balloon",
                        domProps: { innerHTML: _vm._s(devidedMessage) }
                      })
                    ]
                  )
                }),
                _vm._v(" "),
                _vm._l(_vm.message.content.choices, function(choice, key) {
                  return _c(
                    "div",
                    {
                      key: key,
                      staticClass:
                        "scichat-msg-area__box__content__choice-botton",
                      class: _vm.message.isDisabled && "disabled",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.selectAnswer(choice, key)
                        }
                      }
                    },
                    [
                      _vm.showChoiceButtonLeftIcon
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "scichat-msg-area__box__content__choice-botton__left-icon"
                            },
                            [
                              _vm.showDefaultLeftIcon
                                ? _c("font-awesome-icon", {
                                    attrs: { icon: ["fa", "angle-right"] }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showChoiceButtonLeftIcon
                        ? _c("span", {
                            staticClass:
                              "scichat-msg-area__box__content__choice-botton__space"
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "scichat-msg-area__box__content__choice-botton__choice"
                        },
                        [_vm._v(_vm._s(choice))]
                      ),
                      _vm._v(" "),
                      _vm.showChoiceButtonRightIcon
                        ? _c("span", {
                            staticClass:
                              "scichat-msg-area__box__content__choice-botton__space"
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showChoiceButtonRightIcon
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "scichat-msg-area__box__content__choice-botton__right-icon"
                            },
                            [
                              _vm.showDefaultRightIcon
                                ? _c("font-awesome-icon", {
                                    attrs: { icon: ["fa", "angle-right"] }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                })
              ],
              2
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isBot
        ? _c("div", { staticClass: "scichat-msg-area__box" }, [
            _c("div", { staticClass: "scichat-msg-area__box__content" }, [
              _c(
                "div",
                { staticClass: "scichat-msg-area__box__content__time" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.message.displayed_time) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "scichat-msg-area__box__content__passage__balloon"
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "scichat-msg-area__box__content__passage__balloon__text"
                    },
                    [_vm._v(_vm._s(_vm.message.content.text))]
                  )
                ]
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }