import { mapState } from "vuex";

export default {
  props: ["message", "index"],
  data: function() {
    const constObj = this.$store.state.constObj;
    return {
      botIcon: constObj.HAS_BOT_ICON ? this.getBotIcon() : "",
      isBot: this.message.from != "user",
      botName: constObj.BOT_NAME,
      hasBotIcon: constObj.HAS_BOT_ICON,
      fromWhom: this.message.from === "user" ? "user" : "bot",
      showChoiceButtonLeftIcon: constObj.SHOW_CHOICE_BUTTON_LEFT_ICON,
      showChoiceButtonRightIcon: constObj.SHOW_CHOICE_BUTTON_RIGHT_ICON,
      showDefaultLeftIcon: constObj.SHOW_DEFAULT_LEFT_ICON,
      showDefaultRightIcon: constObj.SHOW_DEFAULT_RIGHT_ICON
    };
  },
  computed: {
    firstMessage() {
      return (
        this.index === 0 &&
        this.$store.state.messagesHandler.messages.length === 1
      );
    },
    ...mapState(["constObj"])
  },
  methods: {
    selectAnswer(choice, key) {
      this.$emit("selectAnswer", choice, key);
    },
    splitText() {
      const texts = this.message.content.text.split("<<>>");
      const lineBreakDeleteTexts = texts.map(t =>
        t.replace(/^(\r?\n)+|(\r?\n)+$/g, "")
      );
      const result = [];
      lineBreakDeleteTexts.forEach((element) => {
        result.push(this.supplementAnchorTag(element));
      });
      return result;
    },
    supplementAnchorTag(text) {
      let result = text;
      let matchTextList = text.match(
        /[^\"\'>]https?(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)/g
      );
      if (matchTextList) {
        let resultText = text;
        matchTextList.forEach(element => {
          const linkText = [element].filter(s => !s.match(/["']/g));
          if (linkText.length > 0) {
            const anchorTagLinkText =
              '<a href="' + linkText + '" target="_blank">' + linkText + "</a>";
            resultText = resultText.replace(linkText, anchorTagLinkText);
          }
        });
        result = resultText;
      }
      return result;
    },
    getIconFileName(defaultBotIcon) {
      let iconName = defaultBotIcon;
      const message = this.message.content.text.match(/<emotion:.+?>/);
      if (message) {
        const iconTag = message[0].split(/<emotion:|>/);
        if (iconTag.length >= 2) {
          iconName = iconTag[1];
        }
      }
      return iconName;
    },
    getBotIcon() {
      const constObj = this.$store.state.constObj;
      const defaultBotIcon = constObj.DEFAULT_BOT_ICON;
      const hasBotIcon = constObj.HAS_BOT_ICON;
      const iconName = this.getIconFileName(defaultBotIcon);
      let result = "";
      if (hasBotIcon) {
        result = require("@product/resource/" + iconName + ".png");
      }
      return result;
    }
  }
};
