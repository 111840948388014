import _ from "underscore";
import Hashids from "hashids";
import moment from "moment";
import {
  processPostTicket,
  updateTicketItems,
  initPreviousTicket
} from "common/libs/ticketHandler";

const TICKET_STATUS = {
  'open': "離脱",
  'answered': "回答済み",
  'scriptNotFound': "未収録",
  'searchFailed': "検索失敗",
  're-search': "再検索",
  'quit': "未完了"
};

export default {
  state: {
    ticket: {
      client_user_id: new Hashids("", 16).encode(new Date().getTime())
    },
    previousTicket: {}
  },
  mutations: {
    setTicket(state, payload) {
      state.ticket = payload;
    },
    setPreviousTicket(state, payload) {
      state.previousTicket = payload;
    }
  },
  actions: {
    async postTicket({ state, commit }, addParams) {
      const { ticket, previousTicket } = await processPostTicket({
        ticket: state.ticket,
        previousTicket: state.previousTicket,
        addParams: addParams
      });
      commit("setTicket", ticket);
      commit("setPreviousTicket", previousTicket);
    },
    updateTicketItems({ state, commit }, addParams) {
      const newTicket = updateTicketItems({
        ticket: state.ticket,
        addParams: addParams
      });
      commit("setTicket", newTicket);
    },
    initPreviousTicket({ commit }) {
      commit("setPreviousTicket", initPreviousTicket());
    },
    setTicketContents({ state, rootState }) {
      let feedbackErrorMessage = rootState.constObj.FEEDBACK_ERROR_MESSAGE;
      rootState.constObj.REFLECTED_FEEDBACK_ERROR_MESSAGE = feedbackErrorMessage;
      const ticketTags = feedbackErrorMessage.match(/\[[a-z-]+\]/g);
      const ticket = state.ticket;
      moment.locale("ja");
      var ms = moment.unix(parseInt(ticket.start_date, 10) / 1000);
      var me = moment.unix(parseInt(ticket.end_date, 10) / 1000);
      if (ticketTags) {
        ticketTags.forEach(function (element) {
          switch (element) {
            case '[user-id]':
              feedbackErrorMessage = feedbackErrorMessage.replace(element, ticket.client_user_id);
              break;
            case '[parent-category]':
              feedbackErrorMessage = feedbackErrorMessage.replace(element, ticket.log_faq_parent_category[ticket.log_faq_parent_category.length - 1]);
              break;
            case '[child-category]':
              feedbackErrorMessage = feedbackErrorMessage.replace(element, ticket.log_faq_child_category[ticket.log_faq_child_category.length - 1]);
              break;
            case '[start-time]':
              feedbackErrorMessage = feedbackErrorMessage.replace(element, ms.format('YYYY/MM/DD HH:mm:ss'));
              break;
            case '[end-time]':
              feedbackErrorMessage = feedbackErrorMessage.replace(element, me.format('YYYY/MM/DD HH:mm:ss'));
              break;
            case '[year]':
              feedbackErrorMessage = feedbackErrorMessage.replace(element, ms.format('YYYY'));
              break;
            case '[month]':
              feedbackErrorMessage = feedbackErrorMessage.replace(element, ms.format('MM'));
              break;
            case '[day]':
              feedbackErrorMessage = feedbackErrorMessage.replace(element, ms.format('DD'));
              break;
            case '[day-of-the-week]':
              feedbackErrorMessage = feedbackErrorMessage.replace(element, ms.format('ddd'));
              break;
            case '[status]':
              feedbackErrorMessage = feedbackErrorMessage.replace(element, TICKET_STATUS[ticket.status]);
              break;
            case '[faq-id]':
              feedbackErrorMessage = feedbackErrorMessage.replace(element, ticket.log_faq[ticket.log_faq.length - 1]);
              break;
            case '[question]':
              feedbackErrorMessage = feedbackErrorMessage.replace(element, ticket.log_faq_title[ticket.log_faq_title.length - 1]);
              break;
            case '[search-query]':
              feedbackErrorMessage = feedbackErrorMessage.replace(element, ticket.query ? ticket.query : "");
              break;
            case '[log-scenario]':
              feedbackErrorMessage = feedbackErrorMessage.replace(element, ticket.log_scenario[ticket.log_scenario.length - 1]);
              break;
          }
        });
      }
      rootState.constObj.REFLECTED_FEEDBACK_ERROR_MESSAGE = feedbackErrorMessage;
    }
  }
};
