import _ from "underscore";

export default {
  state: {
    messages: []
  },
  mutations: {
    fetchMessages(state, payload) {
      state.messages = payload;
    },
    addMessage(state, payload) {
      const message = payload;
      const date = new Date();
      const hours = ("00" + date.getHours()).slice(-2);
      const minutes = ("00" + date.getMinutes()).slice(-2);
      message.displayed_time = `${hours}:${minutes}`;
      const dateInt = Date.parse(date);
      message.created_date = dateInt;
      message.updated_date = dateInt;
      state.messages = [...state.messages, message];
    },
    clearMessages(state) {
      state.messages = [];
    },
    disabledChoices(state, payload) {
      state.messages[payload.index] = {
        ...state.messages[payload.index],
        isDisabled: true
      };
    },
    updateUserMessage(state, payload) {
      state.messages[payload.index].status = payload.result;
      state.messages[payload.index].updated_time = new Date();
    }
  },
  actions: {
    processServerMessages(
      { commit, rootState, state, dispatch },
      serverMessages
    ) {
      // extract unduplicated message
      const originMessages = Object.assign([], state.messages);
      const clientMessages = originMessages.filter(m => m.from != "user");
      const lastDate = clientMessages[clientMessages.length - 1].created_date;
      const clientIds = clientMessages.map(m => m.id);
      const array = Array.isArray(serverMessages)
        ? serverMessages
        : [serverMessages];
      const newMessages = array.filter(
        m =>
          m.is_admin &&
          Date.parse(m.created_date) > lastDate &&
          clientIds.indexOf(m.id)
      );

      // remake operator message for client
      let isFeedback;
      if (newMessages.length != 0) {
        const createdMessages = _.map(newMessages, m => {
          const createdDate = Date.parse(m.created_date);
          const operatorMessage = {
            id: `${m.id}`,
            from: "operator",
            status: "sent",
            created_date: createdDate,
            updated_date: createdDate
          };

          const regex = new RegExp(/<feedback:(.*?)>/);
          if (m.text.match(regex) && m.text.match(regex)[1] === "true") {
            isFeedback = true;
            operatorMessage.content = {
              text: m.text.replace(m.text.match(regex)[0], ""),
              choices: rootState.constObj.OPERATOR_FEEDBACK_CHOICES
            };
          } else {
            operatorMessage.content = { text: m.text };
          }
          return operatorMessage;
        });

        // merge newmessages with client state.messages
        dispatch("mergeMessages", originMessages.concat(createdMessages));

        if (isFeedback) {
          dispatch("postTicket", {
            mode: "operator",
            status: "answered"
          });
        }
      }
    },
    mergeMessages({ commit, rootState, state, dispatch }, newMessages) {
      // sort by message.created_date
      newMessages.sort(function(a, b) {
        if (a.created_date < b.created_date) return -1;
        if (a.created_date > b.created_date) return 1;
        return 0;
      });
      commit("fetchMessages", newMessages);
    }
  }
};
