import { mapState } from "vuex";

export default {
  data: function() {
    const constObj = this.$store.state.constObj;
    return {
      chatWindowTitle: constObj.CHAT_WINDOW_TITLE,
      showHeaderIcon: constObj.SHOW_HEADER_ICON,
      showHeaderSubTitle: constObj.SHOW_HEADER_SUB_TITLE,
    };
  },
  computed: {
    ...mapState(["constObj"]),
  },
  methods: {
    toggleWindow() {
      this.$emit("toggleWindow");
    },
    clearMessages() {
      this.$emit("clearMessages");
    },
    getResourceImg(fileName) {
      if (fileName === "sai_icon_close.png") {
        return require("../resource/sai_icon_close.png");
      }

      return require("@product/resource/" + fileName);
    },
  },
};
