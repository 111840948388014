import _ from "underscore";

const STATUS = {
  SCRIPT_NOT_FOUND: "SCRIPT_NOT_FOUND",
  SEARCH_FAILED: "SEARCH_FAILED",
  RESOLVED: "RESOLVED",
  UNRESOLVED: "UNRESOLVED"
};

export default {
  state: {
    isEnqueteOpen: false,
    enqueteMode: ""
  },
  mutations: {
    setIsEnqueteOpen(state) {
      state.isEnqueteOpen = true;
    },
    initIsEnqueteOpen(state) {
      state.isEnqueteOpen = false;
    },
    setEnqueteMode(state, payload) {
      state.enqueteMode = payload;
    },
    initEnqueteMode(state) {
      state.enqueteMode = "";
    }
  },
  actions: {
    async openEnquete({ state, rootState, commit, dispatch }, enqueteMode) {
      commit("setEnqueteMode", enqueteMode);
      commit("setIsEnqueteOpen");
    },
    async submitEnquete({ state, rootState, commit, dispatch }, enquete) {
      // close Modal
      commit("initIsEnqueteOpen");

      const sentMessage = {
        from: "user",
        content: { text: rootState.constObj.ENQUETE_SENT_MESSAGE },
        status: "sent"
      };
      commit("addMessage", sentMessage);

      const enqueteTicket = {
        mode: enquete.enqueteData.mode,
      };

      let text = "";
      const enqueteData = {}
      enquete.enqueteData.values.forEach(element => {
        let id = element.id;
        let label = element.parentLabel;
        let value = element.value;
        let type = element.type;

        if(Array.isArray(element)) {
          id = element[0].id;
          label = element[0].parentLabel;
          type = element[0].type;

          let valueList = [];
          element.forEach(checkboxEle => {
            let obj = {
              label: checkboxEle.label,
              value: checkboxEle.value,
            }
            valueList.push(obj)
          })
          value = valueList;
        }

        if(type === "radio") {
          if (element.value) {
            value = {
              label: element.label,
              value: element.value,
            }
          } else {
            value = null;
          }
        }

        enqueteData[id] = {
          label: label,
          value: value,
          type: type
        }
      });

      switch (enquete.enqueteType) {
        case STATUS.SCRIPT_NOT_FOUND:
          enqueteTicket.status = "scriptNotFound";
          text = rootState.constObj.ENQUETE_UNRESOLVED_MESSAGE;
          enqueteTicket.enquete_unresolved = enqueteData;
          break;
        case STATUS.SEARCH_FAILED:
          enqueteTicket.status = "searchFailed";
          text = rootState.constObj.ENQUETE_UNRESOLVED_MESSAGE;
          enqueteTicket.enquete_unresolved = enqueteData;
          break;
        case STATUS.UNRESOLVED:
          enqueteTicket.status = "answered";
          text = rootState.constObj.ENQUETE_UNRESOLVED_MESSAGE;
          enqueteTicket.enquete_unresolved = enqueteData;
          break;
        case STATUS.RESOLVED:
          enqueteTicket.status = "answered";
          text = rootState.constObj.ENQUETE_RESOLVED_MESSAGE;
          enqueteTicket.enquete_resolved = enqueteData;
          break;
      }

      const ticket = Object.assign(rootState.ticket.previousTicket, enqueteTicket)
      await dispatch("postTicket", ticket);

      commit("initEnqueteMode");

      const doneMessage = {
        from: "bot",
        content: {
          text: text
        },
        status: "sent"
      };
      commit("addMessage", doneMessage);
    },
    async closeEnquete({ state, rootState, commit, dispatch }, type) {
      commit("initIsEnqueteOpen");
      let text = "";
      switch (type) {
        case STATUS.SCRIPT_NOT_FOUND:
          text = rootState.constObj.ENQUETE_UNRESOLVED_CLOSE_MESSAGE;
          break;
        case STATUS.SEARCH_FAILED:
          text = rootState.constObj.ENQUETE_UNRESOLVED_CLOSE_MESSAGE;
          break;
        case STATUS.RESOLVED:
          text = rootState.constObj.FEEDBACK_DONE_MESSAGE;
          break;
        case STATUS.UNRESOLVED:
          text = rootState.constObj.ENQUETE_UNRESOLVED_CLOSE_MESSAGE;
          break;
      }
      const doneMessage = {
        from: "bot",
        content: {
          text: text
        },
      };
      commit("addMessage", doneMessage);
    }
  }
};
