import Vue from "vue";
import VueScrollTo from "vue-scrollto";
import { init, domReady } from "@";
import components from "@product/components";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ProgressBar from "vue-simple-progress";

const config = {
  container: "#saichat-content",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
};

library.add(fas);
library.add(far);
Vue.use(VueScrollTo, config);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("progress-bar", ProgressBar);

domReady(() => {
  init({ components, config });
});
