function numberDetector(text) {
  text = String(text);
  let matchText = text.match(/\D*([0-9０-９])+\D*/gim);

  if (matchText) {
    matchText[0] = matchText[0]
      .replace(/[０-９]/g, s => {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      })
      .replace(/\n/, "");
    matchText[0] = matchText[0].replace(/\D*([0-9]+)\D*/gim, "$1");

    return parseInt(matchText[0]);
  }
  return;
}

const priceDetector = (text = "") => {
  text = String(text);
  text = text.replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => {
    return String.fromCharCode(s.charCodeAt(0) - 65248);
  });
  text = text.replace(/[円￥¥]/gim, "");
  if (text.length == 0) return;
  const match = text.match(/[\d,]+/);
  const manmatch = text.match(/[\d,]+[万萬]/);
  const kansuji = new Array("十", "百", "千", "万", "億", "兆", "京", "垓");
  const kansujiMatch = text.match(
    new RegExp("[" + kansuji.join("") + "]", "gmi")
  );
  if (match && kansujiMatch == null) {
    return parseInt(match[0].replace(",", ""));
  } else if (manmatch) {
    const senmatch = text.match(/[1-9]{1}[千]/);
    let sen = 0;
    if (senmatch == null) {
      const senmatch2 = text.match(/[一二三四五六七八九〇壱弐参伍]{1}千/gim);
      if (senmatch2) {
        sen = parseInt(ktoi(senmatch2[0].replace("千", ""))) * 1000;
      } else {
        const hyakumatch2 = text.match(
          /[萬万][一二三四五六七八九〇壱弐参伍]+/gim
        );
        if (hyakumatch2) {
          sen = parseInt(ktoi(hyakumatch2[0].replace(/[萬万]/, "")));
        } else {
          const hyakumatch3 = text.match(/[萬万][0-9]+/gim);
          if (hyakumatch3) {
            sen = parseInt(hyakumatch3[0].replace(/[萬万]/, ""));
          }
        }
      }
    } else {
      sen = parseInt(senmatch[0].replace("千", "")) * 1000;
    }
    return parseInt(match[0].replace(",", "")) * 10000 + sen;
  } else {
    const detectedPrice = ktoi(text);
    return detectedPrice > 0 ? detectedPrice : null;
  }
  function ktoi(kanji) {
    const taisu = new Array("万", "億", "兆", "京", "垓");
    const taisu2 = new Array("萬", "億", "兆", "京", "垓");
    const ttoa = new Array(
      4,
      8,
      12,
      16,
      20,
      24,
      24,
      28,
      32,
      36,
      40,
      44,
      48,
      52,
      56,
      60,
      64
    );
    var keta = new Array("十", "百", "千");
    var keta2 = new Array("拾", "佰", "阡");
    var kansu = new Array(
      "一",
      "二",
      "三",
      "四",
      "五",
      "六",
      "七",
      "八",
      "九",
      "〇"
    );
    var kansu2 = new Array(
      "壱",
      "弐",
      "参",
      "四",
      "伍",
      "六",
      "七",
      "八",
      "九",
      "零"
    );
    var girisha = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 0);

    var zero = new Array();
    var zeroCounter = 0;

    var suuchi = new Array();
    var suuchiCounter = 0;

    var total = 0;
    for (i = 0; i < kanji.length; i++) {
      if (zero[zeroCounter] == null) {
        zero[zeroCounter] = 0;
      }
      if (suuchi[suuchiCounter] == null) {
        suuchi[suuchiCounter] = 0;
      }

      for (v = 0; v < kansu.length; v++) {
        if (
          kanji[i] == kansu[v] ||
          kanji[i] == girisha[v] ||
          kanji[i] == kansu2[v]
        ) {
          zero[zeroCounter] = zero[zeroCounter] + girisha[v];
          if (
            kanji[i + 1] != null &&
            kanji[i + 1].match(/[一二三四五六七八九〇]/)
          ) {
            zero[zeroCounter] = zero[zeroCounter] * 10;
          }
        }
      }

      for (v = 0; v < keta.length; v++) {
        if (kanji[i] == keta[v] || kanji[i] == keta2[v]) {
          if (zero[zeroCounter] == 0 || zero[zeroCounter] == null) {
            zero[zeroCounter] = 1;
          }
          zero[zeroCounter] = zero[zeroCounter] * Math.pow(10, v + 1);
          zeroCounter++;
        }
      }
      for (v = 0; v < taisu.length; v++) {
        if (kanji[i] == taisu[v] || kanji[i] == taisu2[v]) {
          if (suuchi[suuchiCounter] == 0 || suuchi[suuchiCounter] == null) {
            suuchi[suuchiCounter] = 1;
          }
          var oku = 0;
          var x = 0;
          while (zero[x] != null) {
            oku += zero[x];
            x++;
          }
          suuchi[suuchiCounter] = oku * Math.pow(10, ttoa[v]);
          suuchiCounter++;
          zeroCounter = 0;
          zero = new Array();
        }
      }
    }
    if (
      kanji[kanji.length - 1].match(
        /[一二三四五六七八九〇十百千壱弐参伍拾佰阡零]/
      )
    ) {
      var i = 0;
      while (zero[i] != null) {
        total += zero[i];
        i++;
      }
    }
    for (i = 0; i < suuchi.length; i++) {
      total += suuchi[i];
    }
    return total;
  }
};

module.exports = {
  numberDetector: numberDetector,
  priceDetector: priceDetector
};
